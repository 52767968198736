import React from "react";
import Layout from "./src/components/layout";
import SEO from "./src/components/seo";

import "./src/styles/bootstrap.min.css";
import "./src/styles/custom.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function wrapPageElement({ element, props }) {
  return (
    <>
      <SEO />
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WJVGXGQ"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
      <Layout {...props}>{element}</Layout>
    </>
  )
}
