import React from "react";
import Footer from "../footer/footer";
import HeaderComponent from "../header";
import SlickSlider from "../slick-slider";
import CookieConsent from "react-cookie-consent";

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

export default function Layout(props) {
  const { contentfulCookie } = props.data;
  const cookieText = contentfulCookie?.cookieText?.cookieText;

  return (
    <>
      <main style={pageStyles}>
        <SlickSlider sliderData={props?.data} />
        <HeaderComponent />
        {props.children}
        <Footer data={props.data} />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          style={{ background: "#0e1c3c", alignItems: 'center', justifyContent: 'center' }}
          buttonStyle={{ color: "#0e1c3c", fontSize: "20px", cursor: 'pointer', background: '#ffffff', alignItems: 'center' }}
          cookieName="gatsby-gdpr-google-analytics"
          cookieValue="true"
        >{
            cookieText
          }
        </CookieConsent>
      </main>
    </>
  );
}



