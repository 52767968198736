import React, { useState } from "react";
import { Link, navigate } from "gatsby";

export default function HeaderComponent(props) {
  const [toggle, setToggle] = useState(false);

  const closeMenu = () => {
    document.body.classList.remove("menu-open");
    setToggle(!toggle);
  };

  const isActive = ({ isCurrent }) => {
    return isCurrent ? { className: "active" } : null
  }

  return (
    <header className="page-header">
      <div className="container">
        <div
          className="logo"
          onClick={() => {
            navigate("/");
            closeMenu();
          }}
        >
          <img src="/img/logo.png" alt="LQM Logo" />
        </div>
        <div
          className="mobile-toggle"
          onClick={() => {
            setToggle(!toggle);
            document.body.classList.toggle("menu-open", toggle);
          }}
        >
          <span className="toggle-button"></span>
        </div>
        <div className="main-navigation">
          <div
            className="mobile-logo"
            onClick={() => {
              navigate("/");
              closeMenu();
            }}
          >
            <img src="/img/logo.png" alt="LQM Logo" />
          </div>
          <nav>
            <ul>
              <li>
                <Link
                  getProps={isActive} {...props}
                  to="/property-care"
                  // activeClassName="active"
                  onClick={closeMenu}
                >
                  PROPERTY CARE
                </Link>
              </li>
              <li>
                <Link
                  getProps={isActive} {...props}
                  to="/rental-management"
                  // activeClassName="active"
                  onClick={closeMenu}
                >
                  RENTAL MANAGEMENT
                </Link>
              </li>
              <li>
                <Link
                  getProps={isActive} {...props}
                  to="/luxury-care"
                  // activeClassName="active"
                  onClick={closeMenu}
                >
                  luxury service
                </Link>
              </li>
              <li className="login-link" onClick={closeMenu}>
                <a href="https://app.lqam.com/">
                  <i className="icon"></i>
                  <span>HOMEOWNER LOGIN</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
