import React from 'react';
import { Link } from 'gatsby';

const Footer = ({ data }) => {

  return <footer className="page-footer">
    <div className="container">
      <div className="footer-wrapper">
        <div className="row">
          {/* Who we are */}
          <div className="col-md-4 why-col">
            <div className="fcol">
              <h3>Who We Are</h3>
              <p>
                A full-service luxury property management company providing comprehensive and predictive services with 5-Star luxury standards.
              </p>
            </div>
          </div>

          {/* contact us */}

          <div className="col-md-2 contact-col">
            <div className="fcol">
              <h3>Contact Us </h3>
              <ul className="contact-list">
                <li>
                  <i className="icon">
                    <img src="/img/phone-icon.png" alt="Phone" />
                  </i>
                  <a href="tel:8669724000" className="detail">
                    866-972-4000
                  </a>
                </li>
                <li>
                  <i className="icon">
                    <img src="/img/email-icon.png" alt="Phone" />
                  </i>
                  <a href="mailto:info.pc@lqam.com " className="detail">
                    info.pc@lqam.com
                  </a>
                </li>
                <li>
                  <i className="icon">
                    <img src="/img/contact-us-icon.png" alt="" />
                  </i>
                  <Link to="/contact-us" className="detail">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* about lqam */}
          <div className="col-md-2 about-col">
            <div className="fcol">
              <h3>About LQAM</h3>
              <ul className="about-menu">
                <li>
                  <Link to="/terms">
                    Terms
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/covid-19-protocols">
                    Covid 19 Protocols
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 login-col">
            <div className="fcol">
              <h3>Homeowner Portal</h3>
              <div className="footer-link">
                <a href="https://app.lqam.com/">
                  <i className="icon"></i>
                  <span className="footer-login-text">HOMEOWNER LOGIN</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
}

export default Footer;