import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

function Arrow(props) {

  let className =
    props.type === "next" ? "custom-next-arrow" : "custom-prev-arrow";
  className += " arrow";
  // const char = props.type === "next" ? ">" : "<";
  return (
    <div
      className={className}
      style={{
        ...props.style,
        color: "#FFF",
        display: "block",
        position: "absolute",
        zIndex: 11,
        cursor: 'pointer',
        bottom: 0
      }}
      onClick={props.onClick}
    >
      {
        props.type === "next" ? <img src='/img/arrowright.png' style={{ bottom: 0 }} /> : <img src='/img/arrow-left.png' />
      }
    </div>
  );
}

const SlickSlider = ({ sliderData }) => {
  const { allContentfulNavigationSliderContent } = sliderData;
  const SETTING = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrow: true,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
    slidesToScroll: 1,
  };

  return (
    <section className="header-top">
      <div className="container">
        <Slider {...SETTING} className="header-slick-slider">
          {allContentfulNavigationSliderContent?.nodes?.map((item, index) => {
            const { title: { title }, link, redirectUrl } = item;
            return (
              <div className="text" key={index} >
                <p style={{ zIndex: 10 }}>
                  {title}{" "}
                  <Link to="/contact-us">{link}</Link>
                </p>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  );
};

export default SlickSlider;
