// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-covid-19-protocols-js": () => import("./../../../src/pages/covid-19-protocols.js" /* webpackChunkName: "component---src-pages-covid-19-protocols-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luxury-care-js": () => import("./../../../src/pages/luxury-care.js" /* webpackChunkName: "component---src-pages-luxury-care-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-property-care-js": () => import("./../../../src/pages/property-care.js" /* webpackChunkName: "component---src-pages-property-care-js" */),
  "component---src-pages-rental-management-js": () => import("./../../../src/pages/rental-management.js" /* webpackChunkName: "component---src-pages-rental-management-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

