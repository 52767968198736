import React from 'react';
import { Helmet } from 'react-helmet';


const SEO = () => {
  return (
    <Helmet>
      <title>Home By LQAM</title>
      {/* <link rel="stylesheet" href="css/bootstrap.min.css" />
        <link rel="stylesheet" href="css/custom.css" /> */}
      {/* <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="/css/slick.min.css"
        /> */}
      {/* <link
          rel="stylesheet"
          type="text/css"
          href="/css/slick-theme.min.css"
        /> */}
      <script src="/js/font-awesome.js" type="text/javascript" />
      <link rel='stylesheet' href='https://use.typekit.net/bka2qpg.css' />
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-SSQX7SG96D"></script>
      <script src="/js/google-analytics.js"> </script>
    </Helmet >
  )
}

export default SEO;